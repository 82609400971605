import { FC } from 'react';
import IconProps from './IconProps';

const UsersGroupSolidIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1566 4.32845C14.1566 5.24605 13.4127 5.9899 12.4951 5.9899C11.5775 5.9899 10.8337 5.24605 10.8337 4.32845C10.8337 3.41085 11.5775 2.66699 12.4951 2.66699C13.4127 2.66699 14.1566 3.41085 14.1566 4.32845Z"
        fill="currentColor"
      />
      <path
        d="M6.00033 4.32845C6.00033 5.24605 5.25647 5.9899 4.33888 5.9899C3.42128 5.9899 2.67742 5.24605 2.67742 4.32845C2.67742 3.41085 3.42128 2.66699 4.33888 2.66699C5.25647 2.66699 6.00033 3.41085 6.00033 4.32845Z"
        fill="currentColor"
      />
      <path
        d="M8.417 9.47131C6.53689 9.47131 4.95772 10.8244 4.51423 12.6539C4.37705 13.2198 4.8407 13.7005 5.39672 13.7005H11.4373C11.9933 13.7005 12.4569 13.2198 12.3198 12.6539C11.8763 10.8244 10.2971 9.47131 8.417 9.47131Z"
        fill="currentColor"
      />
      <path
        d="M10.5316 6.37123C10.5316 7.53908 9.58485 8.48581 8.417 8.48581C7.24915 8.48581 6.30242 7.53908 6.30242 6.37123C6.30242 5.20338 7.24915 4.25665 8.417 4.25665C9.58485 4.25665 10.5316 5.20338 10.5316 6.37123Z"
        fill="currentColor"
      />
      <path
        d="M4.91153 10.2983H1.89941C1.45032 10.2983 1.07583 9.92058 1.18662 9.47596C1.54483 8.0385 2.82032 6.97537 4.33886 6.97537C4.81818 6.97537 5.27328 7.08129 5.68296 7.27147C5.91562 7.97824 6.41469 8.56404 7.06134 8.91002C6.22429 9.16657 5.4865 9.65435 4.91153 10.2983Z"
        fill="currentColor"
      />
      <path
        d="M11.151 7.27145C10.9184 7.97823 10.4193 8.56403 9.77267 8.91002C10.6097 9.16656 11.3475 9.65435 11.9225 10.2983H14.9346C15.3836 10.2983 15.7581 9.92058 15.6473 9.47596C15.2891 8.0385 14.0136 6.97537 12.4951 6.97537C12.0158 6.97537 11.5607 7.08128 11.151 7.27145Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default UsersGroupSolidIcon;
